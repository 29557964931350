import Carousel, { CarouselProps } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './ByzzerCarousel.scss';

import { forwardRef, ReactNode } from 'react';
import classnames from 'classnames';

/**Link to follow for documenatation :
 * https://www.npmjs.com/package/react-multi-carousel
 * https://github.com/YIZHUANG/react-multi-carousel/blob/master/stories/index.stories.js
 * https://react-multi-carousel.surge.sh/
 */

export type ByzzerCarouselProps = {
    className?: string;
    slidesToSlide?: number;
    playSpeed?: number;
} & Partial<CarouselProps>;

export const ByzzerCarousel = forwardRef<Carousel, ByzzerCarouselProps>(
    ({ slidesToSlide = 1, className, playSpeed = 1000, ...props }, ref) => {
        const baseClassName = 'byz-carousel';

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
        };

        return (
            <Carousel
                containerClass="container-with-dots"
                infinite={true}
                responsive={responsive}
                draggable={false}
                swipeable={false}
                slidesToSlide={slidesToSlide}
                autoPlaySpeed={playSpeed}
                autoPlay={false}
                // customTransition="all 1s linear"
                // transitionDuration={1000}
                showDots
                // customDot={<CustomDot />}  // Custom dots
            >
                {props.children}
            </Carousel>
        );
    }
);
export default ByzzerCarousel;

ByzzerCarousel.displayName = 'ByzzerCarousel';