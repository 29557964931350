import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext, SyntheticEvent } from "react";
import classnames from 'classnames';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerMask } from "../ByzzerMask/ByzzerMask";
import { UserAlertSummary } from "@/types/AlertRun";
import {AgGridReact} from "ag-grid-react";
import {ColDef} from "ag-grid-community";
import { ByzzerMenu } from "../ByzzerMenu";
import { nanoid } from 'nanoid';
import { useBetterNavigate } from "@/utils";
import { confirm } from '@/components/form/ByzzerModal';
import { ByzzerTable } from "@/components/ByzzerTable";
import { useUser } from '@/contexts/UserContext';
import './AlertConfigurationGrid.scss';

const baseClassName = 'alert-configuration-grid';

export const AlertConfigurationGrid = ({
    savedConfiguredAlerts,
    getAndSetConfiguredAlerts,
    loadingAlerts,
    setIsLoading,
    ...props
}) => {
    const { deleteAlert } = useTenantApi();
    const { user, refreshConfiguredAlerts } = useUser();
    const gridRef = useRef<AgGridReact>(null);
    const menuRefs = useRef<any[]>([]);
    const navigate = useBetterNavigate();


    function handleEdit(userAlert: UserAlertSummary) {
        navigate(`/dashboard/configure_alert`, {
            search: {
                sku: userAlert.productSku,
                alertConfigId: userAlert.id,
            },
            state: {
                userAlert
            }
        });
    };

    async function handleDelete(userAlert: UserAlertSummary) {
        try {
            if (userAlert?.id) {
                if (
                    await confirm({
                        title: 'Are you sure you wish to delete this Alert?',
                        content: <></>,
                        noLabel: 'Cancel',
                        yesLabel: 'Confirm - Delete',
                    })
                ) {
                    setIsLoading(true)
                    await deleteAlert(userAlert?.id);
                    await refreshConfiguredAlerts();
                    setIsLoading(false)
                }
            } else {
                console.warn(`AlertConfigurationGrid - handleDelete fired - WARNING - no alert Id provided...`);
            }
        } catch (err) {
            // tbd
        }

    };

    const disableMenuItems: boolean = user?.role === 'viewer';

    const [columnDefs] = useState<ColDef[]>([
        {
            cellRenderer: ({data: {lastExecutionStatus, status}}: {data: UserAlertSummary}) => {
                return <span className={classnames({
                    [`${baseClassName}__warning`]: lastExecutionStatus === 'failed' || status === 'invalid'
                })} />
            },
            width: 50, 
            filter: false, 
            floatingFilter: false,
            resizable: false,
            lockPosition: true, // same as 'left'
            sortable: false
        },
        {
            headerName: 'Alert Type',
            valueGetter: ({data: {alertProduct}}: {data: UserAlertSummary}) => {
                if (!alertProduct?.title?.length) {
                    return '-'
                }
                return `${alertProduct.title}`
            },
            filterValueGetter: ({data: {alertProduct}}: {data: UserAlertSummary}) => {
                return `${alertProduct?.title}`
            },
        },
        {
            headerName: 'Focus Brand(s)',
            // colSpan: ({data}: {data: UserAlertSummary}) => Boolean(data?.refactoredConfig?.productSelections?.length) ? 2 : 1, // should inspect metadata for includeProductSelections ideally, if this is ever to be used
            valueGetter: ({data: {refactoredConfig}}: {data: UserAlertSummary}) => {
                if (refactoredConfig?.productSelections?.length) {
                    return refactoredConfig?.productSelections.flatMap((ps, i) => ps?.focusBrands)
                }
                return refactoredConfig?.focusBrands;
            },
            cellRenderer: ({value, data: {refactoredConfig}}: {value: string[], data: UserAlertSummary}) => {
                const hasProductSelections = Boolean(refactoredConfig?.productSelections?.length);
                const hasFocusBrands = Boolean(refactoredConfig?.focusBrands?.length);

                if (!hasProductSelections && !refactoredConfig?.focusBrands?.length) {
                    return '-'
                }

                return (
                    <div className={classnames({
                        [`${baseClassName}__focusBrands`]: hasFocusBrands,
                        [`${baseClassName}__productSelections-focusBrands`]: hasProductSelections,
                    })}>
                        {value?.map((val, i) => <p key={nanoid()}>{val}{i !== value.length-1 && hasFocusBrands ? '; ' : ''}</p>)}
                    </div>
                )
            },
            wrapText: true, 
            width: 450    
        },
        {
            headerName: 'Category(s)',
            valueGetter: ({data: {refactoredConfig}}: {data: UserAlertSummary}) => {
                if (refactoredConfig?.productSelections?.length) {
                    return refactoredConfig?.productSelections.flatMap((ps, i) => ps?.categories)
                }
                return refactoredConfig?.categories;
            },
            cellRenderer: ({value, data: {refactoredConfig}}: {value: string[], data: UserAlertSummary}) => {
              
                const hasProductSelections = Boolean(refactoredConfig?.productSelections?.length);
                const hasCategories = Boolean(refactoredConfig?.categories?.length);

                if (!hasProductSelections && !hasCategories) {
                    return '-'
                }

                return (
                    <div className={classnames({
                        [`${baseClassName}__categories`]: hasCategories,
                        [`${baseClassName}__productSelections-categories`]: hasProductSelections,
                    })}>
                        {value?.map((val, i) => <p key={nanoid()}>{val}{i !== value.length-1 && hasCategories ? '; ' : ''}</p>)}
                    </div>
                )
            },
            width: 375,
            wrapText: true,
            autoHeight: true
        },
        {
            headerName: 'Characteristic(s)',
            valueGetter: ({data: {refactoredConfig}}: {data: UserAlertSummary}) => {
                const chars = refactoredConfig?.characteristics?.filter((c)=>Boolean(c.characteristicDisplayValue));
                if (!chars?.length) {
                    return '-'
                }
                return chars.map((c, i) => {
                    return (
                        <React.Fragment key={nanoid()}>
                            {`${c.characteristicDisplayValue} ${c.condition} ${c.value}`}
                        </React.Fragment>
                    )
                })
            },
            filterValueGetter: ({data: {refactoredConfig}}: {data: UserAlertSummary}) => {
                return `${refactoredConfig?.characteristics.map((c) => {
                    return (
                            `${c.characteristicDisplayValue} ${c.condition} ${c.value}`
                    )
                })}`
            },
            cellRenderer: ({data: {refactoredConfig}}: {data: UserAlertSummary}) => {
                const chars = refactoredConfig?.characteristics?.filter((c)=>Boolean(c.characteristicDisplayValue));
                if (!chars?.length) {
                    return '-'
                }
                return chars.map((c, i) => {
                    return (
                        <div key={nanoid()} className={classnames(`${baseClassName}__characteristics`)} >
                            <p>{`${c.characteristicDisplayValue} ${c.condition} ${c.value.join(", ")}${Boolean(i !== chars.length-1) ? '; ' : ''}`}</p>
                        </div>
                    )
                })
            },
            width: 600,
            wrapText: true,
            autoHeight: true
        },
        {
            headerName: 'Focus Market',
            valueGetter: ({data: {refactoredConfig}}: {data: UserAlertSummary}) => {
                if (!refactoredConfig?.markets?.length) {
                    return '-'
                }
                return refactoredConfig?.markets?.map((market) => `${market?.name} ${market?.remainingMarketRunConfig?.name ? `vs ${market?.remainingMarketRunConfig?.name}` : ''}`)
            },
            cellRenderer: ({data: {refactoredConfig}}: {data: UserAlertSummary}) => {
                return (
                    <div className={classnames(`${baseClassName}__focusMarkets`)} >
                        {refactoredConfig?.markets?.map((market, i) => <span key={nanoid()}>{market.name}{market?.remainingMarketRunConfig?.name ? ` vs ${market?.remainingMarketRunConfig?.name}` : ''}{i !== refactoredConfig?.markets.length-1 ? '; ' : ''}</span>)}
                    </div>
                );
            },
            width: 500,
            wrapText: true,
            autoHeight: true
        },
        {
            headerName: 'Notification Recipient(s)',
            valueGetter: ({data: {refactoredConfig}}: {data: UserAlertSummary}) => {
                if (!refactoredConfig?.notification?.recipientEmails?.length) {
                    return '-'
                }
                return refactoredConfig?.notification?.recipientEmails?.map((notif: any) => notif?.email ?? notif)
            },
            filterValueGetter: ({data: {refactoredConfig}}: {data: UserAlertSummary}) => {
                return `${refactoredConfig?.notification?.recipientEmails?.map((notif: any) => notif?.email ?? notif)}`
            },
            cellRenderer: ({value, valueFormatted, data: {refactoredConfig}}) => {
                return (
                    Array.isArray(value) ? (
                        <div className={classnames(`${baseClassName}__notifications`)} >
                            {value?.map((val, i) => <span key={nanoid()}>{val}{i !== value.length-1 ? '; ' : ''}</span>)}
                        </div>
                    ) : value
                );
            },
            // https://www.ag-grid.com/react-data-grid/cell-rendering/
            width: 550,
            wrapText: true,
            autoHeight: true
        },
        {
            // headerName: 'Actions',
            cellRenderer: ({data}: {data: UserAlertSummary}) => {
                const { id } = data;
                return (<>
                    <div 
                        key={id} 
                        className={classnames(`${baseClassName}__menu-trigger`)}
                        ref={thisElement => menuRefs.current[String(id)] = {current: thisElement}}
                    />
                    <ByzzerMenu
                        className={`${baseClassName}__menu`}
                        reference={menuRefs.current[String(id)]}
                        offset={[-110, -30]}
                        triggerTarget={menuRefs.current[String(id)]?.current}
                        items={[
                            {
                                onClick: function() {
                                    handleEdit(data)
                                },
                                content: 'Edit Alert',
                                disabled: disableMenuItems
                            }, 
                            {
                                onClick: function() {
                                    handleDelete(data)
                                },
                                content: 'Delete Alert',
                                disabled: disableMenuItems
                            }
                        ]}
                    />
                </>)
            },
            pinned: "right", 
            width: 65, 
            filter: false, 
            floatingFilter: false,
            resizable: false,
            lockPosition: 'right',
            sortable: false
        }
    ]);

    return (
        <ByzzerTable
            ref={gridRef} // Ref for accessing Grid's API
            rowData={savedConfiguredAlerts} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            baseColumnWidth={360}
        />
    );

};

export default AlertConfigurationGrid;

AlertConfigurationGrid.displayName = 'AlertConfigurationGrid';