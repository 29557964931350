import './StackedReportFilters.scss';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { orderBy } from 'lodash';
import { getAllAlerts, getAllReports } from '@/services/product.service';
import { ExpandableFilter } from '@/components/ExpandableFilter/ExpandableFilter';
import { Spinner } from '@/components/Spinner/Spinner';
import { useApp } from '@/contexts/UserContext';
import FavoriteIcon from '@/components/icons/FavoriteIcon';
import { ProductType, ProductFilterOption } from '@/types/ApiTypes';

const baseClassName = 'stacked-filter-panel';

type FilterSelection = {
    matchType: ProductFilterOption['matchOn'];
    ids: string[]; // numbers array?
};

type ReportFilters = {
    reportHistory?: any[];
    favoriteSkus?: string[];
    onFilter: any;
    onChange?: any;
    from?: string;
    className: string;
    changeReport: boolean;
    appliesTo: ProductType;
};

export function StackedReportFilters({ 
    onFilter,
    favoriteSkus = [],
    className,
    appliesTo = 'report',
    ...props
}: ReportFilters) {
    const { reportFilterOptions } = useApp();
    const [selectedFilterGroups, setSelectedFilterGroups] = useState<FilterSelection[]>([]);
    const [filterFavorites, setFilterFavorites] = useState<boolean>(false);
    const [filterOptions, setFilterOptions] = useState<ProductFilterOption[]>([]);
    const [showFilterPanel, setShowFilterPanel] = useState(true);
    const isAlert = appliesTo === 'alert';

    useEffect(() => {
        loadFilterOptions();
    }, []);

    const loadFilterOptions = () => {
        if (reportFilterOptions) {
            let filtersObjects: ProductFilterOption[] = reportFilterOptions
                .filter((filter) => filter.appliesTo.includes(appliesTo))
                .map((filter) => ({
                    ...filter,
                    values: filter.values.map(({ id, display }) => ({ display, value: id })),
                }))
            filtersObjects = orderBy(filtersObjects, ['display'], "asc") // Sorting report filters alphabetically
            setFilterOptions(filtersObjects);
        }
    };

    useEffect(() => {
        let filteredProducts = isAlert ? getAllAlerts() : getAllReports();
        filteredProducts = filterByFilterOptions(filteredProducts);
        filteredProducts = filterByFavorites(filteredProducts);
        const skus = filteredProducts.map(({ sku }) => sku);
        onFilter?.(skus);
    }, [selectedFilterGroups, filterFavorites]);

    function filterByFavorites(products: ProductWithMeta[]) {
        if (filterFavorites) {
            return products.filter(({ sku }) => favoriteSkus.includes(sku));
        }
        return products;
    }

    function filterByFilterOptions(products: ProductWithMeta[]) {
        if (selectedFilterGroups.length) {
            const filteredData = products.filter(({ filterValueIds }) => {
                return selectedFilterGroups
                    .filter((filter) => filter?.ids.length) //#Added this line to check the array of selected filter `ids` contains value or not
                    .every((filter) => {
                        if (filter.matchType === 'all') {
                            return filter.ids.every((id) => filterValueIds?.includes(+id));
                        } else if (filter.matchType === 'any') {
                            return filter.ids.some((id) => filterValueIds?.includes(+id));
                        }
                    });
            });
            return filteredData;
        }
        return products;
    }

    const handleFavorites = () => {
        setFilterFavorites(!filterFavorites);
    };

    const handleFilterChange = (index: number, value: string[]) => {
        const filterChange = [...selectedFilterGroups];
        filterChange[index] = { matchType: filterOptions[index].matchOn, ids: value };
        setSelectedFilterGroups(filterChange);
    };

    function toggleFilterPanel() {
        setShowFilterPanel((current) => !current);
    }

    return (
        <div 
            className={classnames(`${baseClassName}__container`, 
                {
                    [`${baseClassName}__container--expanded`]: showFilterPanel
                }
            )}
        >
            <div 
                className={classnames(`${baseClassName}__container-toggle`, 
                    {
                        [`${baseClassName}__container-toggle-expanded`]: showFilterPanel
                    }
                )} 
                onClick={toggleFilterPanel}
            />
            {showFilterPanel ? (
                <div className={`${baseClassName}__filter-options-wrapper`}>
                    <div className={classnames(baseClassName, className)}>
                        {!filterOptions.length && (
                            <div className={baseClassName}>
                                <Spinner />
                            </div>
                        )}
                        {!isAlert && <div className={baseClassName + '__favorites'}>
                            <FavoriteIcon
                                className={baseClassName + '__favorites--icon'}
                                trackClick={`Filter by favorites`}
                                selected={filterFavorites}
                                onClick={handleFavorites}
                            />
                            <h1 className={baseClassName + '__favorites--title'}>Filter by favorites</h1>
                        </div>}
                        {filterOptions.map((filter, index) => {
                            const filterValue = selectedFilterGroups[`${index}`]?.ids || [];
                            return (
                                <ExpandableFilter
                                    title={filter.display}
                                    onChange={({ value }) => handleFilterChange(index, value)}
                                    value={filterValue}
                                    key={`product-filter-${index}`}
                                    options={filter.values}
                                />
                            );
                        })}
                    </div>
                </div>
            ) : <div className={`${baseClassName}__new-filters-after-hidden`} onClick={toggleFilterPanel} />}
        </div>
    );
}

export default StackedReportFilters;
