import './ReportViewer.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerErrorModal, ByzzerLink } from '@/components/form';
import { scorecardConfigs, SCORECARDS_BY_ID } from '@/config/scorecard.config';
import { alert } from '@/components/form';
import { Tooltip } from '@material-ui/core';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import ReportFiltersOverlay from '@/pages/ReportViewer/ReportFilterOverlay';
import classnames from 'classnames';
import { useBetterNavigate } from '@/utils';
import { cloneDeep, uniq } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { openDownloadReport } from '@/views/ReportViewer/DownloadReport';
import { useUser } from '@/contexts/UserContext';

const baseClassName = 'report-viewer';
const EMPTY_LOOKER_REPORT_URL = 'about:blank';

export default function ScorecardViewer() {
    const { getRemainingMarkets, getScorecardReport } = useTenantApi();
    const { maxDataDates } = useUser();
    const navigate = useBetterNavigate();
    const navigateTo = useNavigate();
    const { state } = useLocation();
    const location = useLocation();
    const { scorecardId } = useParams();

    const [loading, setLoading] = useState(false);
    const [lookerReportUrl, setLookerReportUrl] = useState(EMPTY_LOOKER_REPORT_URL);
    const [showFilters, setShowFilters] = useState(false);
    const [menuExpanded, setMenuExpanded] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [contentType, setContentType] = useState('warning');
    const [reportConfig, setReportConfig] = useState({});
    const [scorecardConfig, setScorecardConfig] = useState(null);
    const [disableFiltering, setDisableFiltering] = useState(false);
    const [companyCategories, setCompanyCategories] = useState(state?.companyCategories);
    const [drivers, setDrivers] = useState([]);
    const [actionId, setActionId] = useState({});
    const [showDropDown, setShowDropDown] = useState(false);
    const [filterData, setFilterData] = useState(null);
    const useStyles = makeStyles(() => ({
        customTooltip: {
            backgroundColor: '#000',
            border: '1px solid #D6D6D6',
            borderRadius: '0',
            fontSize: '16px',
            padding: '5px 20px',
        },
        customArrow: {
            color: '#000',
            '&:before': {
                border: '1px solid #D6D6D6',
            },
        },
    }));

    useEffect(() => {
        const scorecard = SCORECARDS_BY_ID[scorecardId];
        if (scorecard) {
            const drivers = scorecardConfigs
                .filter(({ driver }) => scorecard.driver === driver)
                .map(({ sku, lever, key }) => ({
                    sku,
                    display: lever,
                }))
                .sort((a, b) => a.display.localeCompare(b.display));

            //BYZ-11026 and BYZ-8266 - combined fix

            const driversByUniqueByDisplay = [...new Map(drivers.map((item) => [item['display'], item])).values()];

            setDrivers(driversByUniqueByDisplay);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDisableFiltering(
            !reportConfig?.focusProductSelections?.showFocusBrand &&
                reportConfig?.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'cps'
        );
    }, [reportConfig]);

    useEffect(() => {
        const scoreCardConfig = SCORECARDS_BY_ID[scorecardId];
        setScorecardConfig(scoreCardConfig);
    }, [scorecardId]);

    useEffect(() => {
        if (scorecardConfig) {
            loadReport();
        }
    }, [scorecardConfig]);

    async function loadReport() {
        try {
            setLoading(true);
            setLookerReportUrl(EMPTY_LOOKER_REPORT_URL);
            const { focusBrands, focusCategories, markets, companyCategories, ppgId,sortingField,sortingFieldValue } = state;
            let timePeriods = {};
            console.log(state?.filterData, 'loadreport');
            if (scorecardConfig?.selectors?.timePeriod) {
                timePeriods = {
                    timePeriod: 'Latest 52 Weeks', // Need to pass Latest 52 Weeks by default as per #2287
                    selectionType: 'pre-defined',
                };
            }
            const filters = state?.filterData;
            const { url, actionID } = await getScorecardReport({
                scorecardId,
                focusBrands: filters?.focusProductSelections?.focusBrand ?? focusBrands,
                focusCategories: filters?.focusProductSelections?.competitiveProductSet?.categories ?? focusCategories,
                timePeriod: filters?.timePeriodSelections ?? timePeriods,
                characteristics: filters?.focusProductSelections?.characteristicFilters,
                customSegmentIds: filters?.focusProductSelections?.customSegmentIds,
                markets: await appendRemainingMarkets(filters?.focusMarketSelections?.focusMarket?.byMarketName?.markets ?? markets),
                companyCategories,
                ppgId: filters?.ppgId ?? ppgId,
                sortingField:sortingField,
                sortingFieldValue:sortingFieldValue,
            });

            setLookerReportUrl(url);
            setActionId(actionID);
            setReportConfig({
                uncharged: true,
                focusProductSelections: {
                    focusBrand: filters?.focusProductSelections?.focusBrand ?? focusBrands,
                    showFocusBrand: true,
                    focusUPC: '', // Will be blank from here but may have a value in the wrapper
                    competitiveProductSet: {
                        categories: filters?.focusProductSelections?.competitiveProductSet?.categories ?? focusCategories,
                        subcategories: [],
                    },
                    characteristicDimension: '',
                    characteristicFilters: filters?.focusProductSelections?.characteristicFilters || [],
                    customSegmentIds: filters?.focusProductSelections?.customSegmentIds || [],
                    customSegmentData: filters?.focusProductSelections?.customSegmentData || [],
                    ppgId: filters?.ppgId,
                },
                focusMarketSelections: {
                    focusMarket: {
                        byMarketName: {
                            marketGroup: 'rms',
                            markets: filters?.focusMarketSelections?.focusMarket?.byMarketName?.markets ?? markets,
                        },
                        byMarketType: null,
                    },
                },
                timePeriodSelections: filters?.timePeriodSelections ?? timePeriods,
                comparisonType: '',
                ppgId: filters?.ppgId,
            });
        } catch (err) {
            console.error(err);
            // todo handle error with meaningful message
            setLoading(false);
        }
    }

    function goBack() {
        const { focusBrands, focusCategories, markets, companyCategories, activeLever, currentLeverSku } = state;
        if (location.pathname !== `/scorecard/${currentLeverSku}`) 
            navigateTo(-1);
        else {
            navigate(
                '/dashboard/my_scorecard/assess_levers',
                {
                    state: {
                        brandParam: focusBrands,
                        categoryParam: focusCategories,
                        marketParam: markets,
                        activeLever: activeLever
                    },
                },
                { replace: false }
            );
        }
    }

    const downloadReport = async () => {
        await openDownloadReport({ actionId });
    };

    function onScorecardLoad() {
        if (lookerReportUrl !== EMPTY_LOOKER_REPORT_URL) {
            setLoading(false);
        }
    }

    async function applyFilters(filters) {
        try {
            setLoading(true);
            setLookerReportUrl('about:blank');
            const { url, actionID } = await getScorecardReport({
                scorecardId,
                focusBrands: filters.focusProductSelections.focusBrand,
                focusUPC: filters.focusProductSelections.focusUPC,
                focusCategories: filters.focusProductSelections.competitiveProductSet.categories,
                markets: await appendRemainingMarkets(filters.focusMarketSelections.focusMarket.byMarketName.markets),
                timePeriod: filters.timePeriodSelections ?? {
                    endDate: '',
                    weeks: '',
                    timePeriod: 'Latest 52 Weeks', // Need to pass Latest 52 Weeks by default as per #2287
                    selectionType: 'pre-defined',
                },
                characteristics: filters.focusProductSelections.characteristicFilters,
                customSegmentIds: filters.focusProductSelections.customSegmentIds,
                companyCategories,
                ppgId: filters.ppgId,
                sortingField:sortingField,
                sortingFieldValue:sortingFieldValue,
            });

            setLookerReportUrl(url);
            setActionId(actionID);
            setShowFilters(false);
        } catch (err) {
            setLoading(false);
        }
    }

    async function appendRemainingMarkets(markets) {
        const remainingMarkets = [];
        const includeRemainingMarkets = scorecardConfig?.selectors?.markets.remaining;

        // this should be includeRemainingMarkets flag
        if (markets.length && includeRemainingMarkets) {
            const remainingMarketInfo = await getRemainingMarkets(markets);

            remainingMarketInfo.forEach(
                ({ rm_xaoc_mrkt_disp_name, rm_channel_mrkt_disp_name, comparative_mkt_disp_name }) => {
                    if (rm_xaoc_mrkt_disp_name) {
                        remainingMarkets.push(rm_xaoc_mrkt_disp_name);
                    } else if (rm_channel_mrkt_disp_name) {
                        remainingMarkets.push(rm_channel_mrkt_disp_name);
                    } else if (comparative_mkt_disp_name) {
                        remainingMarkets.push(comparative_mkt_disp_name);
                    }
                }
            );
        }
        return uniq([...markets, ...remainingMarkets]);
    }

    const toggleFilterPanel = () => {
        setShowFilters(!showFilters);
        setShowDropDown(false);
    };

    const onMenuItemClick = async (scorecardId) => {
        setMenuExpanded(false);
        console.log('navigating');
        navigate(`/scorecard/${scorecardId}`, {
            state: { ...state, filterData: cloneDeep(filterData) },
            replace: false,
        });
    };

    const onMenuClickAlert = ({ callback }) => {
        alert({
            onResolve: callback,
            content: (
                <>
                    <ul>
                        <li className="popover-main-text">
                            This report will help you assess your new product fit across markets based on key features
                            of your products. Select key characteristics in the top menu to run the report.
                        </li>
                        <li className="popover-sub-text">
                            If you don't make this selection, the Key Characteristic fields will not populate, but you
                            can still assess fit based on category performance and pricing.
                        </li>
                    </ul>
                </>
            ),
        });
    };

    async function onApplyFilters(filters) {
        setFilterData({ ...filters });
        applyFilters(filters);
    }

    function onCancelFilters() {
        setShowFilters(false);
    }
    const classes = useStyles();
    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__header`}>
                <span className="report-viewer-header__logo" onClick={() => goBack()}></span>
                {
                    <span className="report-viewer-header__brandName" onClick={() => goBack()}>
                        Byzzer
                    </span>
                }
                <div className={`${baseClassName}__menu`} />
                <div
                    className={classnames(`${baseClassName}__filter-toggle`, {
                        [`${baseClassName}__filter-toggle--disabled`]: disableFiltering,
                        [`${baseClassName}__filter-toggle--open`]: showFilters,
                    })}
                ></div>

                <div className={`${baseClassName}__header-actions`}>
                    <Tooltip
                        placement="bottom"
                        title="Download"
                        arrow
                        classes={{
                            tooltip: classes.customTooltip,
                            arrow: classes.customArrow,
                        }}
                    >
                        <span className="download-report" onClick={() => downloadReport()} target={'_blank'}></span>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title="Back"
                        arrow
                        classes={{
                            tooltip: classes.customTooltip,
                            arrow: classes.customArrow,
                        }}
                    >
                        <span className="exit-report" onClick={() => goBack()}></span>
                    </Tooltip>
                    <span className="dropDown" onClick={!showFilters && (() => setShowDropDown(!showDropDown))}></span>
                </div>
            </div>
            <div className={`${baseClassName}__menuItems`} style={{ display: showDropDown ? 'block' : 'none' }}>
                <div className={`${baseClassName}__underlineStyle`}>Report Menu</div>
                <div className={`${baseClassName}__menuItemstyle`}>
                    <ByzzerLink
                        className={`${baseClassName}__menuItemstyle-item`}
                        label={'Filter current report'}
                        onClick={() => toggleFilterPanel()}
                    />
                </div>
                <div className={`${baseClassName}__underlineStyle`}>{scorecardConfig?.title.split(':')[0]} Menu</div>
                <div className={`${baseClassName}__menuItemstyle`}>
                    {drivers.map((driver) => (
                        <ByzzerLink
                            key={driver.sku}
                            className={`${baseClassName}__menuItemstyle-item ${
                                driver.sku === scorecardId && baseClassName + '__menuItemstyle-item-highlighted'
                            }`}
                            label={driver.display}
                            onClick={() => {
                                onMenuItemClick(driver.sku);
                                setShowDropDown(false);
                            }}
                        />
                    ))}
                </div>
            </div>
            <div className={`${baseClassName}__content`}>
                <ByzzerMask loading={loading}>Your Report Is Loading</ByzzerMask>
                {lookerReportUrl !== EMPTY_LOOKER_REPORT_URL && (
                    <iframe
                        src={lookerReportUrl}
                        className={`${baseClassName}__looker-report`}
                        onLoad={onScorecardLoad}
                    />
                )}
                <ReportFiltersOverlay
                    title="Change Data Scope"
                    show={showFilters}
                    reportConfig={reportConfig}
                    maxDataDate={maxDataDates.rms}
                    onCancel={onCancelFilters}
                    onApply={onApplyFilters}
                    scorecardId={scorecardId}
                    includeCategories={true}
                    includeMultiMarket={true}
                    includeThemes={true}
                    includeUpcs={true}
                    includeFocusBrands={true}
                    includeTimePeriod
                    includeCharacteristics
                    includePpg
                    toggleFilterPanel={toggleFilterPanel}
                />
            </div>
            {showWarning && (
                <ByzzerErrorModal
                    showWarning={showWarning}
                    setShowWarning={setShowWarning}
                    headerType={'none'}
                    contentType={contentType}
                    warningMessage={warningMessage}
                    size={'small'}
                />
            )}
        </div>
    );
}
