import './DashboardHeader.scss';
import React, {useEffect, useRef, useState} from 'react';
import {openTermsAndConditions} from '@/components/modals/TermsAndConditions';
import {useEvents, useUser} from '@/contexts/UserContext';
import 'firebase/compat/firestore';
import {useBetterNavigate} from '@/utils';
import classnames from 'classnames';
import {ByzzerMenu} from "@/components/ByzzerMenu";
import {openModal, useModals} from "@/components/form/ByzzerModal";
import {openProductTours} from "@/components/modals/ProductTours";
import { Notification } from '@/pages/HomePage/Notification';
import { useAccountService } from '@/hooks/useAccountService';
import VersionInfo from '../VersionInfo/VersionInfo';
import { ByzzerProgressBar, ByzzerProgressBarProps } from '@/components/ByzzerProgressBar';
import { useTenantApi } from '@/hooks';
import filterAlertsByStatus from '@/utils/filterAlertsByStatus';

const baseClassName = 'dashboard-header';

type DashboardHeaderProps = {
    disabled?: boolean;
    className?: string;
}

export function DashboardHeader({disabled = false, className}: DashboardHeaderProps) {
    const {user, company, isCsrObo, subscription, activeNotifications, availableTenants, isMultiTenant, configuredAlerts } = useUser();
    const { getMyCompanySubscriptionUsage } = useTenantApi();
    const navigate = useBetterNavigate();
    const {confirm} = useModals()
    const accountRef = useRef<HTMLDivElement>(null);
    const itemRef = useRef<HTMLDivElement>(null);
    const helpRef = useRef<HTMLDivElement>(null);
    const notificationsRef = useRef<HTMLDivElement>(null);
    const progressRef = useRef<HTMLDivElement>(null);
    const { logout } = useAccountService();
    const events = useEvents();

    const subscriptionInactive = !subscription?.active;
    const [subscriptionUsage, setSubscriptionUsage] = useState<Omit<ByzzerProgressBarProps, 'className'>[]>([]);

    useEffect(() => {
        const getCompanySubscription = async () => {
            const arr: Omit<ByzzerProgressBarProps, 'className'>[] = [];
            const { 
                // 'basic' = 'core',
                // 'premium' = 'smart'
                basicReports,
                premiumReports,
                basicAlerts,
                premiumAlerts 
            } = await getMyCompanySubscriptionUsage();

            const filteredConfigureAlerts = configuredAlerts && filterAlertsByStatus(configuredAlerts); 

            arr.push(
                {
                    currentProgress: basicReports.used,
                    total: basicReports.limit,
                    title: 'Core Runs',
                    lowThreshold: 70,
                    highThreshold: 90,
                },
                {
                    currentProgress: premiumReports.used,
                    total: premiumReports.limit,
                    title: 'Smart Runs',
                    lowThreshold: 70,
                    highThreshold: 90,
                },
                {
                    currentProgress: filteredConfigureAlerts.length,
                    total: basicAlerts.limit + premiumAlerts.limit,
                    title: 'Alerts',
                    lowThreshold: 70,
                    highThreshold: 90,
                }
            );
            setSubscriptionUsage(arr);
        }

        getCompanySubscription();
    }, [configuredAlerts, events]); // Events are used as a dependent variable because they facilitate the updating of subscription usage after running reports, stories, or DoD.

    const handleLogout = async () => {
        await logout();
        if (isCsrObo) {
            navigate('/auth/csr_sign_in');
        } else {
            navigate('/auth/sign_in');
        }
    }

    const ByzzerMenuChildrenProgressBar: React.FC<{ subscriptionUsage: Omit<ByzzerProgressBarProps, 'className'>[], baseClassName: string, subscriptionInactive: boolean }> = ({ subscriptionUsage, baseClassName, subscriptionInactive }) => {
        return (
            <>
                {subscriptionUsage
                    .filter((item) => subscriptionInactive ? item : item.total > 0)
                    .map((item) => {
                        return (
                            <ByzzerProgressBar
                                className={`${baseClassName}__progress`}
                                title={item.title}
                                currentProgress={item.currentProgress}
                                total={item.total}
                                highThreshold={item.highThreshold}
                                lowThreshold={item.lowThreshold}
                                key={item.title}
                            />
                        );
                    })}
            </>
        );
    };

    return (<>
        <div className={classnames(baseClassName, className)}>
            {isMultiTenant && (
                <div className={classnames(`${baseClassName}__tenant`)}>
                    <div className={classnames(
                        `${baseClassName}__tenant-user-role`,
                        `${baseClassName}__tenant-user-role--${user?.role}`, 
                        { // todo: remove for production
                            [`${baseClassName}__tenant-user-role-ab`]: localStorage['tenant-order'] === 'role-company',
                            [`${baseClassName}__tenant-user-role-ba`]: localStorage['tenant-order'] === 'company-role',
                        }
                    )}>{user?.role}</div>
                    <div className={classnames(
                        `${baseClassName}__tenant-company-name`, 
                        { // todo: remove for production
                            [`${baseClassName}__tenant-company-name-ab`]: localStorage['tenant-order'] === 'company-role',
                            [`${baseClassName}__tenant-company-name-ba`]: localStorage['tenant-order'] === 'role-company'
                        }
                    )}>{company?.displayName}</div>
                </div>
            )}
            <div className={classnames(`${baseClassName}__nav-item`,`${baseClassName}__nav-item--notifications`)}
                 ref={notificationsRef}>
                {activeNotifications?.length > 0 && <span className={`${baseClassName}__nav-item--notifications-badge`}>{activeNotifications?.length < 99 ? activeNotifications.length || 0 : '99+'}</span> }
            </div>
            <div className={classnames(`${baseClassName}__nav-item`,`${baseClassName}__nav-item--progress`)} ref={progressRef}></div>
            <div ref={itemRef}
                 className={classnames(`${baseClassName}__nav-item`, `${baseClassName}__nav-item--my-items`)}>
                My Items
            </div>
            <div ref={accountRef}
                 className={classnames(`${baseClassName}__nav-item`, `${baseClassName}__nav-item--account`)}>
                {user?.firstName}
            </div>
            <div ref={helpRef}
                 className={classnames(`${baseClassName}__nav-item`, `${baseClassName}__nav-item--help`)}/>
        </div>
        <ByzzerMenu className={`${baseClassName}__menu`}
                    reference={itemRef}
                    offset={[0, 2]}
                    triggerTarget={itemRef.current}
                    items={[
                        {
                            // to: '/dashboard/characteristic_explorer/client',
                            to: '/dashboard/static_report/characteristic-explorer-for-client',
                            iconType: 'char-explorer',
                            content: 'Characteristic Explorer',
                            disabled: subscriptionInactive
                        }, {
                            // @ts-ignore
                            showIf: import.meta.env.REACT_APP_SHOW_CHARACTERISTICS_EXPLORER_INTERNAL === 'true',
                            // to: '/dashboard/characteristic_explorer/niq',
                            to: '/dashboard/static_report/characteristic-explorer-for-niq',
                            iconType: 'char-explorer',
                            content: 'Characteristic Explorer - Internal',
                            disabled: subscriptionInactive
                         },
                        {
                            to: '/dashboard/custom_characteristics',
                            iconType: 'custom-chars',
                            content: 'Custom Characteristics',
                            disabled: subscriptionInactive
                        },
                        {
                            to: '/dashboard/static_report/item_coding_explorer',
                            iconType: 'item-explorer',
                            content: 'Item Coding Explorer',
                            disabled: subscriptionInactive
                        },
                        {
                            to: '/dashboard/my_portfolio',
                            iconType: 'portfolio',
                            content: 'Portfolio',
                            disabled: subscriptionInactive
                        },
                        {
                            to: '/dashboard/ppgs',
                            iconType: 'ppgs',
                            content: 'PPGs',
                            disabled: subscriptionInactive
                        },
                        {
                            to: '/dashboard/product_coding_request',
                            iconType: 'product-coding',
                            content: 'Product Coding Form',                        },

                        {
                            to: '/dashboard/product_lookup',
                            iconType: 'product-lookup',
                            content: 'Product Lookup',
                            disabled: subscriptionInactive
                        }, {
                            // @ts-ignore
                            showIf: import.meta.env.REACT_APP_SHOW_AOD_HIERARCHY_LOOKUP === 'true',
                            to: '/dashboard/my_items/aod_hierarchy_lookup',
                            iconType: 'product-lookup',
                            content: 'AOD Hierarchy Lookup',
                            disabled: subscriptionInactive
                        },
                    ]}/>
        <ByzzerMenu className={`${baseClassName}__menu`}
                    reference={accountRef}
                    triggerTarget={accountRef.current}
                    offset={[0, 2]}
                    header={<header className={'account-menu__header'}>
                        <div className={'account-menu__name'}>{user?.firstName} {user?.lastName}</div>
                        <div className={'account-menu__company'}>{company?.displayName}</div>
                    </header>}
                    items={[
                        {
                            content: 'Subscription',
                            to: '/dashboard/my_subscription/subscription'
                        },
                        {
                            content: 'Profile',
                            to: '/dashboard/user_profile/preferences'
                        },
                        {
                            content: 'Legal',
                            onClick() {
                                openTermsAndConditions();
                            }
                        },
                        ...(isMultiTenant ? [{
                            content: 'Switch Company',
                            to: '/dashboard/switch_company'
                        }]: []),
                        {
                            content: 'Logout',
                            onClick: handleLogout,
                        },
                    ]}/>
        <ByzzerMenu className={`${baseClassName}__menu`}
                    reference={helpRef}
                    offset={[0, 2]}
                    triggerTarget={helpRef.current}
                    items={[{
                        onClick(type) {
                            // todo: add popup blocker detection
                            window.open('https://learn.byzzer.ai', '_resources');
                        },
                        content: 'Resource Library',
                        iconType: 'resource-library'
                    }, {
                        to: '/dashboard/prior_approved',
                        content: 'Prior Approval Markets',
                        iconType: 'prior-approval-market',
                    }, {
                        onClick(type) {
                            openProductTours()
                        },
                        content: 'Product Tours',
                        iconType: 'product-tours',
                    }, {
                        to: '/dashboard/market_maps/retailer',
                        content: 'Market Maps',
                        iconType: 'market-maps',
                    }, {
                        content: 'Partnerships',
                        to: '/dashboard/partnerships',
                        iconType: 'partnerships',
                    }, {
                        async onClick() {
                            await openModal({
                                title: 'Version Info',
                                size: 'small',
                                content: <VersionInfo />,
                            })
                        },
                        content: 'Version Info',
                        iconType: 'version-info',
                    }
                ]}
            />
        <ByzzerMenu className={`${baseClassName}__menu`} 
                header={<div className={`${baseClassName}__progress-header`}>Subscription Status</div>}
                items={[]}
                reference={progressRef}
                triggerTarget={progressRef.current}
                children={<ByzzerMenuChildrenProgressBar subscriptionUsage={subscriptionUsage} baseClassName={baseClassName} subscriptionInactive={subscriptionInactive}/>}/>
        <ByzzerMenu className={`${baseClassName}__menu`}
            reference={notificationsRef}
            triggerTarget={notificationsRef.current}
            items={[]}
            children={<Notification className={`${baseClassName}__notifications`} componentView='header'/>}/>
    </>);
}


export default DashboardHeader;
