import './SegmentEditor.scss';
import { ByzzerInput } from '@/components/form';
import {ByzzerButton} from '@byzzer/ui-components';
import React, { useEffect, useState } from 'react';
import {useTenantApi} from '@/hooks/useTenantApi';
import { SegmentSelector } from './SegmentSelector';
import { SegmentPreview } from './SegmentPreview';

const baseClassName = 'segment-editor';

export function SegmentEditor({
    index,
    onCreateSegment,
    segment,
    characteristicsForCategories,
    characteristicOptions,
    selectedCategories,
    cancelable = true,
    onCancel,
    setLoading,
}) {
    const { getCharacteristicValues } = useTenantApi();
    const [char, setChar] = useState([{ characteristic: '', condition: 'is', value: [], valueOptions: [] }]);
    const [segmentName, setSegmentName] = useState('');

    useEffect(() => {
        setChar([{ characteristic: '', condition: 'is', value: [], valueOptions: [] }]);
        if (segment?.char) {
            preloadCharacteristicsData(segment?.char);
        }
        setSegmentName(segment?.name);
    }, [segment]);

    const preloadCharacteristicsData = (chars) => {
        let newChars = [...chars];
        chars.forEach(async (item, index) => {
            newChars[index].characteristics = item.characteristic;
            newChars[index].value = item.value;
            const charValuesOptions = await fetchCharsValues(item.characteristic, selectedCategories);
            newChars[index].valueOptions = charValuesOptions;
            newChars[index].condtion = item.condition;
            setChar([...newChars]);
        });
    };

    const addCondition = () => {
        const newChar = { characteristic: '', condition: 'is', value: [], and: 'and', valueOptions: [] };
        setChar(prevChar => [...prevChar, newChar]);
    };
    const onCharacteristicChange = async (index, e) => {
        char[index].characteristic = e;
        char[index].value = [];
        let charValuesOptions = await fetchCharsValues(e, selectedCategories);
        char[index].valueOptions = charValuesOptions;
        setChar([...char]);
    };
    const onConditionChange = (index, e) => {
        char[index].condition = e;
        setChar([...char]);
    };
    const onValueChange = (index, e) => {
        char[index].value = e;
        setChar([...char]);
    };
    const onToggleChange = (index, value) => {
        char[index].and = value;
        setChar([...char]);
    };
    const onRemoveSelector = (index, element) => {
        char.splice(index, 1);
        setChar([...char]);
    };
    const checkAllChar = () => {
        let result = false;
        char.forEach((item) => {
            if (Object.values(item).indexOf('') > -1) {
                result = true;
            }
        });
        return result;
    };

    function onCancelClick() {
        onCancel?.();
    }

    async function fetchCharsValues(charItem, categoriesList = []) {
        setLoading(true);
        try {
            let charsValueOptions = [];
            const productSet = categoriesList?.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            });
            let charsAndCodes = [...characteristicsForCategories];
            const charValsResp = await getCharacteristicValues({
                categories: productSet,
                code: charsAndCodes?.find((item) => item.chr_display_name === charItem)?.chr_code,
            });
            charsValueOptions = [...charValsResp];
            setLoading(false);
            return charsValueOptions;
        } catch (error) {
            console.log('fetchCharsValues error', error);
        } finally {
            setLoading(false);
        }     
    }

    return (
        <div className={baseClassName}>
            <ByzzerInput
                label={<>Segment {index + 1}</>}
                className={`${baseClassName}__name`}
                type={'text'}
                placeholder={'Enter label for segment'}
                value={segmentName}
                onChange={(e) => setSegmentName(e.target.value)}
                variant={'secondary'}
            />
            {char.map((item, index) => (
                <SegmentSelector
                    index={index}
                    char={item}
                    key={index}
                    removable={index > 0}
                    includeToggle={index > 0}
                    selectedCategories={selectedCategories}
                    characteristicsForCategories={characteristicsForCategories}
                    characteristicOptions={characteristicOptions}
                    onCharacteristicChange={onCharacteristicChange}
                    onConditionChange={onConditionChange}
                    onValueChange={onValueChange}
                    onToggleChange={(value) => onToggleChange(index, value)}
                    onRemoveSelector={onRemoveSelector}
                    variant={'secondary'}
                />
            ))}

            <div className={`${baseClassName}__add-condition`} onClick={addCondition}>
                + Add another condition
            </div>

            <SegmentPreview char={char} />

            <div className={`${baseClassName}__actions`}>
                {cancelable && (
                    <ByzzerButton onClick={onCancelClick} type={'negative'}>
                        Cancel
                    </ByzzerButton>
                )}
                <ByzzerButton
                    disabled={checkAllChar() || !segmentName}
                    onClick={() => onCreateSegment({ name: segmentName, char: char })}
                >
                    {segment ? 'Save' : 'Create'} Segment
                </ByzzerButton>
            </div>
        </div>
    );
}

export default SegmentEditor;
