import CaseStudy from './CaseStudy';

const CaseStudyWrapper = (props) => {
    return (
        <>
            <CaseStudy baseClassName="casestudy__wrapper" {...props} />
        </>
    );
};

export default CaseStudyWrapper;
